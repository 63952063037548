<template>
  <div class="ori-invest">
    <ori-header />

    <div class="banner">
      <img
        :src="require('@/assets/images/ori/202012030922021793.jpg')"
        alt=""
        srcset=""
      />
    </div>

    <match-filter />

    <div class="invest-list-wrap">
      <div class="invest-content w1200">
        <el-row :gutter="15">
          <el-col :span="12" v-for="i in list" :key="i.id">
            <div class="invest-box">
              <div class="avatar">
                <img :src="i.avatar" alt="" srcset="" />
              </div>
              <div class="info">
                <div class="name-title-share solo-row">
                  <div class="name-title">
                    <span>{{ i.cname }}</span>
                    <span>{{ i.title }}</span>
                  </div>
                  <div class="share-area">
                    <span>
                      <i class="el-icon-location-outline"></i>
                      {{ i.city }}
                    </span>
                    <span>
                      <i class="el-icon-share"></i>
                    </span>
                  </div>
                </div>

                <div class="info-row">
                  <span>机构名称：</span>
                  <span>{{ i.coName }}</span>
                </div>
                <div class="info-row">
                  <span>主要轮次：</span>
                  <span>{{ i.rzTag }}</span>
                </div>
                <div class="info-row">
                  <span>反馈率：</span>
                  <span>{{ i.count }}</span>
                </div>
                <div class="info-row hy-tag-wrap">
                  <span>主要领域：</span>
                  <span>{{ i.hyTag }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 分页 -->
    <div class="w1200 pagination-wrap">
      <ori-pagination />
    </div>

    <page-foot />
  </div>
</template>

<script>
import { investData } from "@/Mock";
import PageFoot from "../../../components/ori-com/PageFoot.vue";
import MatchFilter from "../../../components/ori-com/Select/MatchFilter.vue";
export default {
  components: { PageFoot, MatchFilter },
  name: "ori-invest",
  data() {
    return {
      list: investData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-invest {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;

  .banner {
    width: 100%;
    overflow: hidden;
  }

  .invest-list-wrap {
    margin-top: 20px;

    .invest-content {
      .invest-box {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        margin-bottom: 15px;
        padding: 22px;

        &:hover,
        &:active {
          box-shadow: 0 0 15px #ddd;
          .avatar img {
            transform: scale(1.2);
          }
        }

        .avatar {
          // margin-top: 4px;
          width: 128px;
          height: 128px;
          overflow: hidden;
          margin-right: 27px;
          img {
            transition: all 0.8s ease-in;
          }
        }

        .info {
          flex: 1 1 auto;
          // margin-bottom: 8px;
          .name-title-share {
            display: flex;
            justify-content: space-between;
            align-items: center;

            height: 20px;
            line-height: 20px;

            margin-bottom: 8px;

            .name-title {
              font-size: 14px;

              span {
                display: inline-block;
                max-width: 85px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 15px;
                color: #999;
              }
              span:nth-child(1) {
                color: #333;
                font-weight: bold;
              }
            }

            .share-area {
              span:nth-child(1) {
                margin-right: 15px;
                vertical-align: middle;
                font-size: 12px;
                color: #666;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100px;
                i {
                  font-size: 14px;
                }
              }
              span:nth-child(2) {
                i {
                  color: #999;
                }
              }
            }
          }

          .info-row {
            margin-bottom: 8px;
            line-height: 1;
            span:nth-child(1) {
              vertical-align: middle;
              font-size: 12px;
              color: #999;
              display: inline-block;
            }
            span:nth-child(2) {
              font-size: 12px;
              color: #666;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 300px;
              vertical-align: middle;
            }

            &.hy-tag-wrap {
              span:nth-child(n + 2) {
                border-radius: 3px;
                text-align: center;
                display: inline-block;
                padding: 5px 10px;
                width: 85px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #f4f5f9;
                margin-right: 12px;
                color: #666;
              }
            }
          }
        }
      }
    }
  }

  .pagination-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
  }
}
</style>